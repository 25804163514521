<script setup lang="ts">
import { sleep, useHttpClient } from "@/vf"
import FilledButton from "@/wallet/components/FilledButton.vue"
import OutlineButton from "@/wallet/components/OutlineButton.vue"
import OverlappingElement from "@/wallet/components/OverlappingElement.vue"
import RoundedCard from "@/wallet/components/RoundedCard.vue"
import { useUserStore } from "@/wallet/stores/user-store"
import { AxiosError } from "axios"
import { ref } from "vue"
import { useRouter } from "vue-router"

const username = ref("")
const password = ref("")

const state = ref<"input" | "fail">("input")
const loginError = ref("")

const { post } = useHttpClient()
const userStore = useUserStore()
const router = useRouter()

async function submit() {
    try {
        await post("/wallet/login", { username, password })
        await userStore.refresh()

        if (userStore.authenticated) {
            router.push({ name: "dashboard" })
            return
        }
    } catch (e) {
        if (e instanceof AxiosError) {
            const response = e.response

            if (e.response?.data.error == "Invalid credentials.") {
                loginError.value = "E-Mailadresse oder Passwort falsch."
                return
            }

            if (response?.status === 406 && response?.data.error === "requiresPasswordChange") {
                router.push({ name: "reset-password" })
                return
            }

            loginError.value = e.response?.data.error
        }
    }

    state.value = "fail"
    await sleep(2000)
    state.value = "input"
}
</script>

<template>
    <Transition :duration="500" appear>
        <OverlappingElement class="w-3/4 lg:w-2/3 xl:w-1/2 mx-auto">
            <template #under>
                <img src="@/wallet/assets/icons/lock.svg" />
            </template>
            <template #default>
                <form @submit.prevent="submit">
                    <RoundedCard back-link="/" color="modal" :class="{ 'animate-shake': state === 'fail' }">
                        <template #title>Scoopas Wallet Login</template>

                        <template #default>
                            <div
                                class="text-red-500 mt-2 opacity-0 transition translate-y-5 delay-500"
                                :class="{ 'opacity-100 !translate-y-0': state === 'fail' }"
                            >
                                {{ loginError }}
                            </div>
                            <div class="mb-10 mt-5">
                                <input
                                    type="email"
                                    class="input-field input-field-white"
                                    :class="{ 'shadow-red-500': state === 'fail' }"
                                    placeholder="E-Mailadresse"
                                    v-model="username"
                                />
                                <input
                                    type="password"
                                    class="input-field input-field-white"
                                    :class="{ 'shadow-red-500': state === 'fail' }"
                                    placeholder="Passwort"
                                    v-model="password"
                                />

                                <div class="mt-2 text-placeholder pl-8">
                                    Passwort vergessen? Dann setzen Sie es
                                    <RouterLink :to="{ name: 'lost-password' }" class="underline">hier</RouterLink>
                                    zurück.
                                </div>
                            </div>

                            <div class="flex justify-items-stretch gap-8">
                                <RouterLink class="grow" :to="{ name: 'registration' }">
                                    <OutlineButton class="w-full">Jetzt registrieren</OutlineButton>
                                </RouterLink>
                                <div class="grow">
                                    <FilledButton class="w-full" type="submit">Jetzt sicher einloggen</FilledButton>
                                </div>
                            </div>
                        </template>
                    </RoundedCard>
                </form>
            </template>
        </OverlappingElement>
    </Transition>
</template>
