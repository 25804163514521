<script setup lang="ts">
import { useUserStore } from "@/wallet/stores/user-store"
import { storeToRefs } from "pinia"

const userStore = useUserStore()
const { authenticated } = storeToRefs(userStore)
</script>

<template>
    <div class="bg-secondary text-white py-2">
        <div class="container mx-auto flex justify-between items-center flex-col lg:flex-row">
            <div class="mb-3 md:mb-0">&copy; Scoopas GmbH. Alle Rechte vorbehalten.</div>
            <div class="flex gap-3 items-center flex-col md:flex-row md:gap-8">
                <RouterLink :to="{ name: 'terms-of-service' }">AGB</RouterLink>
                <RouterLink :to="{ name: 'data-privacy' }">Datenschutz</RouterLink>
                <RouterLink :to="{ name: 'token-disclaimer' }" v-if="authenticated">Tokenbedingungen</RouterLink>
                <RouterLink :to="{ name: 'purchase-conditions' }" v-if="authenticated">Kaufbedingungen</RouterLink>
                <RouterLink :to="{ name: 'imprint' }">Impressum</RouterLink>
            </div>
        </div>
    </div>
</template>
