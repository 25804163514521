<script setup lang="ts">
import { AnimateIf } from "@/vf"
import { useUserStore } from "@/wallet/stores/user-store"
import { ref, watch } from "vue"
import { useRoute } from "vue-router"
import Fa6SolidBars from "~icons/fa6-solid/bars"
import Fa6SolidXmark from "~icons/fa6-solid/xmark"
import NavBarMenuItems from "./NavBarMenuItems.vue"

const userStore = useUserStore()
const showMobileMenu = ref(false)

const route = useRoute()
watch(route, () => (showMobileMenu.value = false))
</script>

<template>
    <div class="bg-secondary text-white">
        <div class="container mx-auto p-5 flex flex-row justify-between items-center">
            <RouterLink :to="{ name: userStore.authenticated ? 'dashboard' : 'home' }" class="italic text-3xl">
                <b>Scoopas</b>
                <span>Wallet</span>
            </RouterLink>

            <div class="grid place-items-center lg:hidden text-xl">
                <Transition
                    enter-active-class="transition duration-500"
                    enter-from-class="scale-0 opacity-0"
                    enter-to-class="scale-100 opacity-100"
                    leave-active-class="transition duration-500"
                    leave-from-class="scale-100 opacity-100"
                    leave-to-class="scale-0 opacity-0"
                >
                    <button
                        class="col-start-1 col-span-1 row-start-1 row-span-1 m-0 focus:bg-transparent"
                        v-if="!showMobileMenu"
                        @click="showMobileMenu = true"
                    >
                        <Fa6SolidBars />
                    </button>
                    <button
                        class="col-start-1 col-span-1 row-start-1 row-span-1 m-0 focus:bg-transparent"
                        v-else
                        @click="showMobileMenu = false"
                    >
                        <Fa6SolidXmark />
                    </button>
                </Transition>
            </div>

            <NavBarMenuItems class="hidden lg:flex" />
        </div>

        <AnimateIf :if="showMobileMenu">
            <NavBarMenuItems class="xl:hidden" />
        </AnimateIf>
    </div>
</template>

<style scoped>
nav a {
    @apply hover:text-slate-300 transition-colors duration-200;
}
</style>
