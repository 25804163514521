<script setup lang="ts">
import { $currency } from "@/vf"
import OutlineButton from "@/wallet/components/OutlineButton.vue"
import RoundedCard from "@/wallet/components/RoundedCard.vue"
import TransitionUp from "@/wallet/components/TransitionUp.vue"
import { gsap } from "gsap"
import { ref, watch } from "vue"
import { useRoute } from "vue-router"
import FilledButton from "../components/FilledButton.vue"
import { useUserStore } from "../stores/user-store"

const goBuyTokensButton = ref<HTMLDivElement>()
const goDashboardButton = ref<HTMLDivElement>()

const route = useRoute()

watch(
    () => route.name,
    routeName => {
        if (routeName === "buy-tokens") {
            gsap.to(goBuyTokensButton.value!, { height: 0, opacity: 0 })
            gsap.to(goDashboardButton.value!, { height: "auto", opacity: 1 })
        }

        if (routeName === "dashboard") {
            gsap.to(goDashboardButton.value!, { height: 0, opacity: 0 })
            gsap.to(goBuyTokensButton.value!, { height: "auto", opacity: 1 })
        }
    },
)

const visibleButtonOnLoad = route.name
const userStore = useUserStore()
</script>

<template>
    <TransitionUp>
        <RoundedCard color="secondary">
            <div class="flex justify-between flex-col md:flex-row">
                <div>
                    <div class="card-title font-bold">Aktueller Preis</div>
                    <div class="flex items-start mt-3">
                        <span class="text-5xl font-bold">
                            {{ $currency(userStore.currentEuroCentsPerToken!) }}
                        </span>
                        <span class="text-2xl ml-3 mt-2"></span>
                    </div>
                </div>
            </div>

            <div
                ref="goBuyTokensButton"
                class="overflow-y-hidden flex justify-center md:block"
                :style="visibleButtonOnLoad === 'buy-tokens' ? 'height: 0; opacity: 0' : ''"
            >
                <RouterLink :to="{ name: 'buy-tokens' }">
                    <FilledButton color="primary" class="mt-5 transition">Scoopas Music Token kaufen</FilledButton>
                </RouterLink>
            </div>

            <div
                ref="goDashboardButton"
                class="overflow-y-hidden flex justify-center md:block"
                :style="visibleButtonOnLoad === 'dashboard' ? 'height: 0; opacity: 0' : ''"
            >
                <RouterLink :to="{ name: 'dashboard' }">
                    <OutlineButton color="white" class="mt-5">Zurück</OutlineButton>
                </RouterLink>
            </div>
        </RoundedCard>
    </TransitionUp>

    <RouterView></RouterView>
</template>
