import ChangeEmailView from "@/wallet/views/ChangeEmailView.vue"
import BuyTokensView from "@/wallet/views/dashboard/BuyTokensView.vue"
import SummaryView from "@/wallet/views/dashboard/SummaryView.vue"
import DashboardView from "@/wallet/views/DashboardView.vue"
import HomeView from "@/wallet/views/HomeView.vue"
import DataPrivacyView from "@/wallet/views/legal/DataPrivacyView.vue"
import ImprintView from "@/wallet/views/legal/ImprintView.vue"
import PurchaseConditionsView from "@/wallet/views/legal/PurchaseConditionsView.vue"
import TermsOfServiceView from "@/wallet/views/legal/TermsOfServiceView.vue"
import TokenDisclaimerView from "@/wallet/views/legal/TokenDisclaimerView.vue"
import EnterPasswordView from "@/wallet/views/login/EnterPasswordView.vue"
import LostPasswordView from "@/wallet/views/login/LostPasswordView.vue"
import ResetPasswordView from "@/wallet/views/login/ResetPasswordView.vue"
import LoginView from "@/wallet/views/LoginView.vue"
import MyProfileView from "@/wallet/views/MyProfileView.vue"
import RegistrationView from "@/wallet/views/RegistrationView.vue"
import SecuritySettingsView from "@/wallet/views/SecuritySettingsView.vue"
import TransactionView from "@/wallet/views/TransactionView.vue"
import VerifyView from "@/wallet/views/VerifyView.vue"
import WalletView from "@/wallet/views/WalletView.vue"
import { createRouter, createWebHistory } from "vue-router"
import { useUserStore } from "../stores/user-store"

const router = createRouter({
    history: createWebHistory(import.meta.env.DEV ? "/wallet" : "/"),
    routes: [
        {
            path: "/",
            name: "home",
            component: HomeView,
        },
        {
            path: "/impressum",
            name: "imprint",
            component: ImprintView,
        },
        {
            path: "/agb",
            name: "terms-of-service",
            component: TermsOfServiceView,
        },
        {
            path: "/datenschutz",
            name: "data-privacy",
            component: DataPrivacyView,
        },
        {
            path: "/token-bedingungen",
            name: "token-disclaimer",
            component: TokenDisclaimerView,
            meta: { requiresLogin: true },
        },
        {
            path: "/kaufbedingungen",
            name: "purchase-conditions",
            component: PurchaseConditionsView,
            meta: { requiresLogin: true },
        },
        // {
        //     path: "/risikobelehrung",
        //     name: "risk-advise",
        //     component: RiskAdviseView,
        //     meta: { requiresLogin: true },
        // },
        {
            path: "/login",
            component: LoginView,
            name: "login-layout",
            meta: { hideLayout: true },
            children: [
                {
                    path: "/login",
                    name: "login",
                    component: EnterPasswordView,
                    meta: { loginTransition: true },
                },
                {
                    path: "/passwort-vergessen",
                    name: "lost-password",
                    component: LostPasswordView,
                    meta: { loginTransition: true },
                },
                {
                    path: "/passwort-zuruecksetzen",
                    name: "reset-password",
                    component: ResetPasswordView,
                    meta: { loginTransition: true },
                },
                {
                    path: "/passwort-zuruecksetzen/:token",
                    name: "reset-password-code",
                    component: ResetPasswordView,
                    props: true,
                    meta: { loginTransition: true },
                },
            ],
        },
        {
            path: "/registrieren",
            name: "registration",
            component: RegistrationView,
        },
        {
            path: "/verify/:code",
            name: "verify",
            component: VerifyView,
            props: true,
        },
        {
            path: "/email-aendern/:code",
            name: "verify-mail",
            component: ChangeEmailView,
            props: true,
        },
        // {
        //     path: "/_dev/elements",
        //     name: "_dev_elements",
        //     component: () => import("@/wallet/views/_dev/ElementsView.vue"),
        // },
        {
            path: "/wallet",
            name: "wallet",
            component: WalletView,
            children: [
                {
                    path: "/dashboard",
                    name: "dashboard-layout",
                    component: DashboardView,
                    meta: { requiresLogin: true },
                    children: [
                        {
                            path: "/dashboard",
                            name: "dashboard",
                            component: SummaryView,
                            meta: { title: "Meine Wallet", requiresLogin: true },
                        },
                        {
                            path: "/token-kaufen",
                            name: "buy-tokens",
                            component: BuyTokensView,
                            meta: { title: "SMT kaufen", requiresLogin: true },
                        },
                    ],
                },
                {
                    path: "/mein-profil",
                    name: "my-profile",
                    component: MyProfileView,
                    meta: { title: "Mein Profil", requiresLogin: true },
                },
                {
                    path: "/sicherheits-einstellungen",
                    name: "security-settings",
                    component: SecuritySettingsView,
                    meta: { title: "Sicherheit", requiresLogin: true },
                },
                {
                    path: "/aktivitaeten",
                    name: "transactions",
                    component: TransactionView,
                    meta: { title: "Sicherheit", requiresLogin: true },
                },
            ],
        },
    ],
})

router.beforeEach(async to => {
    const title = to.meta.title

    if (to.meta.requiresLogin) {
        const userStore = useUserStore()
        await userStore.ensureLoaded()

        if (!userStore.authenticated) {
            console.error("[Router]: Not logged in")
            return { name: "login" }
        }
    }

    if (title) {
        document.title = `${title} • Scoopas Wallet`
    } else {
        document.title = "Scoopas Wallet"
    }

    return true
})

router.afterEach(() => {
    window.scrollTo(0, 0)
})

export default router
