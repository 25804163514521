<script setup lang="ts">
import type { Transaction } from "@/model/app/transaction"
import { useHttpGet, type ListResponse } from "@/vf"
import OutlineButton from "@/wallet/components/OutlineButton.vue"
import RoundedCard from "@/wallet/components/RoundedCard.vue"
import TransactionItem from "@/wallet/components/TransactionItem.vue"
import TransitionUp from "@/wallet/components/TransitionUp.vue"

const { loading, content } = useHttpGet<ListResponse<Transaction>>("/wallet/transaction/?itemsPerPage=5", false)
</script>

<template>
    <TransitionUp active-class="delay-[400ms]">
        <RoundedCard>
            <div class="card-title">
                <b>Kürzliche</b>
                Aktivitäten
            </div>

            <div>
                <template v-if="loading">
                    <div
                        v-for="_ of 5"
                        class="border-b border-b-gray-200 py-3 flex justify-between items-end last:border-b-0 animate-pulse"
                        :key="_"
                    >
                        <div class="w-2/3">
                            <div class="h-5 w-1/3 rounded bg-secondary opacity-10 mb-1"></div>
                            <div class="h-5 w-2/3 rounded bg-secondary opacity-10"></div>
                        </div>
                        <div class="w-1/3 flex items-start justify-end">
                            <div class="h-5 w-20 rounded bg-secondary opacity-10"></div>
                        </div>
                    </div>
                </template>

                <template v-else>
                    <div v-if="content!.list.length === 0" class="opacity-30 text-center py-24">
                        Hier erscheinen Ihre Buchungen und Reservierungen.
                    </div>
                    <template v-for="transaction of content!.list" :key="transaction.id!">
                        <TransactionItem :transaction="transaction" />
                    </template>
                </template>
            </div>

            <RouterLink :to="{ name: 'transactions' }" v-if="content?.list.length !== 0">
                <OutlineButton color="secondary" class="mt-5">Alles anzeigen</OutlineButton>
            </RouterLink>
        </RoundedCard>
    </TransitionUp>
</template>
