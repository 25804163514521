<script setup lang="ts">
import { ref } from "vue"
import MaterialSymbolsEdit from "~icons/material-symbols/edit"
import FilledButton from "../components/FilledButton.vue"
import OutlineButton from "../components/OutlineButton.vue"
import RoundedCard from "../components/RoundedCard.vue"
import TransitionUp from "../components/TransitionUp.vue"
import { useUserStore } from "../stores/user-store"
import ChangeMailView from "./security-settings/ChangeMailView.vue"
import ChangePasswordView from "./security-settings/ChangePasswordView.vue"

const userStore = useUserStore()
const show = ref<null | "mail" | "password">(null)

async function open(which: null | "mail" | "password") {
    if (show.value === which) {
        return
    }

    if (show.value !== null) {
        show.value = null
        await new Promise(resolve => setTimeout(resolve, 400))
    }

    show.value = which
}
</script>

<template>
    <TransitionUp>
        <RoundedCard color="secondary">
            <div class="card-title">
                <b>Sicherheits</b>
                Einstellungen
            </div>

            <div class="flex italic gap-3 py-4 border-b border-gray-400">
                <div>E-Mailadresse</div>
                <b class="text-right grow">{{ userStore.email }}</b>
                <div @click="open('mail')" class="cursor-pointer"><MaterialSymbolsEdit /></div>
            </div>

            <div class="flex italic gap-3 py-4 border-b border-gray-400">
                <div>Passwort</div>
                <b class="text-right grow">&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;</b>
                <div @click="open('password')" class="cursor-pointer"><MaterialSymbolsEdit /></div>
            </div>

            <div class="mt-10">
                <FilledButton color="primary" class="px-11" @click="open('mail')">E-Mail ändern</FilledButton>
                <OutlineButton color="primary" class="px-11" @click="open('password')">Passwort ändern</OutlineButton>
            </div>
        </RoundedCard>
    </TransitionUp>

    <TransitionUp>
        <ChangeMailView v-if="show === 'mail'" @close="show = null" />
    </TransitionUp>

    <TransitionUp>
        <ChangePasswordView v-if="show === 'password'" @close="show = null" />
    </TransitionUp>
</template>
