<script setup lang="ts">
import { useHttpClient, VfFeedbackError, VfFeedbackSuccess } from "@/vf"

import OutlineButton from "@/wallet/components/OutlineButton.vue"
import { useAsyncState } from "@vueuse/core"
import { ref } from "vue"
import GridLoader from "vue-spinner/src/GridLoader.vue"
import FilledBackgroundContainer from "../components/FilledBackgroundContainer.vue"
import OverlappingElement from "../components/OverlappingElement.vue"
import RoundedCard from "../components/RoundedCard.vue"
import { useUserStore } from "../stores/user-store"

const props = defineProps<{
    code: string
}>()

const http = useHttpClient()
const success = ref(false)
const userStore = useUserStore()

const { isLoading } = useAsyncState(async () => {
    try {
        await http.post("/wallet/verify/change-email", { code: props.code })
        success.value = true
    } catch (e) {
        console.error(e)
    }
}, undefined)
</script>

<template>
    <FilledBackgroundContainer class="grid w-full h-full">
        <OverlappingElement class="w-1/2 mx-auto col-start-1 col-span-1 row-start-1 row-end-1">
            <template #under></template>
            <template #default>
                <RoundedCard color="modal" :back-button="false">
                    <template #default>
                        <template v-if="isLoading">
                            <div class="flex flex-col items-center">
                                <GridLoader color="#E4027E" :loading="true"></GridLoader>
                            </div>
                        </template>
                        <template v-else-if="success">
                            <VfFeedbackSuccess />
                            <div class="text-center">
                                <div class="mb-10">Ihre E-Mail Adresse wurde erfolgreich geändert.</div>
                                <RouterLink v-if="userStore.authenticated" :to="{ name: 'dashboard' }">
                                    <OutlineButton>Zum Dashboard</OutlineButton>
                                </RouterLink>
                                <RouterLink v-else :to="{ name: 'login' }">
                                    <OutlineButton>Zum Login</OutlineButton>
                                </RouterLink>
                            </div>
                        </template>
                        <template v-else>
                            <VfFeedbackError />
                            Da ist etwas schief gelaufen. Eventuell ist der Link nicht mehr gültig.
                        </template>
                    </template>
                </RoundedCard>
            </template>
        </OverlappingElement>
    </FilledBackgroundContainer>
</template>
