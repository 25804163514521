<script setup lang="ts">
import MaterialSymbolsVerified from "~icons/material-symbols/verified"
import UserIcon from "~icons/mdi/user"
import ClaimableTokenList from "../components/ClaimableTokenList.vue"
import WalletMenuItem from "../components/dashboard/WalletMenuItem.vue"
import RoundedCard from "../components/RoundedCard.vue"
import { useUserStore } from "../stores/user-store"

const userStore = useUserStore()
</script>

<template>
    <div class="container mx-auto px-5 xl:px-0">
        <div class="mt-10"></div>
        <ClaimableTokenList />

        <h1 class="ml-5 text-3xl font-extrabold mb-6 xl:mt-16">Meine Wallet</h1>
        <div class="flex gap-8 my-10 flex-col lg:flex-row">
            <div class="lg:w-3/4 flex flex-col gap-8">
                <RouterView></RouterView>
            </div>
            <div class="lg:w-1/4 hidden lg:block">
                <RoundedCard color="white" class="text-gray-800">
                    <div class="flex items-center gap-4 mb-7">
                        <div
                            class="rounded-full aspect-square bg-secondary grid place-items-center w-14 h-14 bg-cover bg-center bg-no-repeat text-2xl text-white"
                        >
                            <UserIcon />
                        </div>
                        <div>
                            <div
                                class="text-primary text-sm leading-3 flex items-center gap-0.5"
                                v-if="userStore.isVerified"
                            >
                                <MaterialSymbolsVerified class="inline" />
                                Verifiziert
                            </div>
                            <div class="underline font-extrabold text-lg">
                                {{ userStore.fullName }}
                            </div>
                        </div>
                    </div>

                    <div>
                        <WalletMenuItem :to="{ name: 'dashboard' }">Dashboard</WalletMenuItem>
                        <WalletMenuItem :to="{ name: 'my-profile' }">Mein Profil</WalletMenuItem>
                        <WalletMenuItem class="border-b-0" :to="{ name: 'security-settings' }">
                            Sicherheit
                        </WalletMenuItem>
                    </div>

                    <button
                        @click="userStore.logout"
                        class="mt-20 border-t border-gray-200 py-2 underline block cursor-pointer w-full text-left"
                    >
                        Sicher ausloggen
                    </button>
                </RoundedCard>
            </div>
        </div>
    </div>
</template>
