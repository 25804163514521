<script lang="ts" setup>
import type { Transaction } from "@/model/app/transaction"
import { useHttpGet, type ListResponse } from "@/vf"
import RoundedCard from "@/wallet/components/RoundedCard.vue"
import TransactionItem from "@/wallet/components/TransactionItem.vue"
import { useIntersectionObserver } from "@vueuse/core"
import { ref, watch } from "vue"
import TransitionUp from "../components/TransitionUp.vue"

const bottom = ref<HTMLElement>()
const transactions = ref<Transaction[]>()
let currentPage = 1
let endReached = false

const { loading, data } = useHttpGet<ListResponse<Transaction>>("/wallet/transaction/?itemsPerPage=10", false)

watch(
    () => loading.value,
    () => {
        transactions.value = data.value?.list
    },
)

// https://github.com/vueuse/vueuse/issues/1685#issuecomment-1185050680
useIntersectionObserver(
    bottom,
    async () => {
        if (!loading.value && !endReached) {
            loading.value = true
            currentPage++
            const { data } = await useHttpGet<ListResponse<Transaction>>(
                "/wallet/transaction/?itemsPerPage=10&page=" + currentPage,
            )
            if (data!.value!.list.length === 0) {
                endReached = true
            } else {
                transactions.value?.push(...data!.value!.list)
            }
            loading.value = false
        }
    },
    {
        threshold: 0.5,
    },
)
</script>

<template>
    <TransitionUp>
        <RoundedCard>
            <div class="card-title">
                <b>Alle</b>
                Aktivitäten
            </div>

            <div>
                <template v-for="transaction of transactions" :key="transaction.id!">
                    <TransactionItem :transaction="transaction" />
                </template>
            </div>
            <template v-if="loading">
                <div
                    v-for="_ of [1, 2, 3, 4, 5]"
                    class="border-b border-b-gray-200 py-3 flex justify-between items-end last:border-b-0 animate-pulse"
                    :key="_"
                >
                    <div class="w-2/3">
                        <div class="h-5 w-1/3 rounded bg-secondary opacity-10 mb-1"></div>
                        <div class="h-5 w-2/3 rounded bg-secondary opacity-10"></div>
                    </div>
                    <div class="w-1/3 flex items-start justify-end">
                        <div class="h-5 w-20 rounded bg-secondary opacity-10"></div>
                    </div>
                </div>
            </template>

            <span ref="bottom"></span>
        </RoundedCard>
    </TransitionUp>
</template>
