<script setup lang="ts">
import { $longDate, useHttpClient } from "@/vf"
import OutlineButton from "@/wallet/components/OutlineButton.vue"
import type { FormKitNode } from "@formkit/core"
import { AxiosError } from "axios"
import { reactive, ref, watch } from "vue"
import DetailRow from "../components/DetailRow.vue"
import FilledButton from "../components/FilledButton.vue"
import RoundedCard from "../components/RoundedCard.vue"
import TransitionUp from "../components/TransitionUp.vue"
import { useUserStore } from "../stores/user-store"

const userStore = useUserStore()
const edit = ref(false)
const editCardTitle = ref<HTMLElement | null>(null)

watch(
    () => edit.value,
    () => {
        if (edit.value) {
            setTimeout(() => {
                editCardTitle.value?.scrollIntoView({ behavior: "smooth" })
            }, 400)
        }
    },
)
const model = reactive({
    street: userStore.street,
    zipCode: userStore.zipCode,
    city: userStore.city,
    phone: userStore.phone,
})

async function submit(data: any, node?: FormKitNode) {
    const http = useHttpClient()
    try {
        await http.put("/wallet/my-profile", model)
        await userStore.refresh()
        edit.value = false
    } catch (e) {
        if (e instanceof AxiosError) {
            node?.setErrors(
                e.response?.data._, // (optional) An array of form level errors
                e.response?.data, // (optional) input level errors
            )
        }
        console.error(e)
    }
}
</script>
<template>
    <TransitionUp>
        <RoundedCard color="secondary">
            <div class="card-title">
                <b>Persönliche</b>
                Informationen
            </div>

            <DetailRow locked>
                <template #label>Kundennummer</template>
                <template #value>{{ userStore.number }}</template>
            </DetailRow>

            <DetailRow locked v-if="userStore.company">
                <template #label>Firma</template>
                <template #value>{{ userStore.company }}</template>
            </DetailRow>

            <DetailRow locked>
                <template #label>Vorname</template>
                <template #value>{{ userStore.firstName }}</template>
            </DetailRow>

            <DetailRow locked>
                <template #label>Nachname</template>
                <template #value>{{ userStore.lastName }}</template>
            </DetailRow>

            <DetailRow locked>
                <template #label>Geburtstag</template>
                <template #value>{{ $longDate(userStore.birthdate) }}</template>
            </DetailRow>

            <DetailRow @edit="edit = true">
                <template #label>Straße</template>
                <template #value>{{ userStore.street }}</template>
            </DetailRow>

            <DetailRow @edit="edit = true">
                <template #label>PLZ</template>
                <template #value>{{ userStore.zipCode }}</template>
            </DetailRow>

            <DetailRow @edit="edit = true">
                <template #label>Ort</template>
                <template #value>{{ userStore.city }}</template>
            </DetailRow>

            <DetailRow @edit="edit = true">
                <template #label>Telefon</template>
                <template #value>{{ userStore.phone }}</template>
            </DetailRow>

            <FilledButton color="primary" class="mt-10 px-11" @click="edit = !edit">Bearbeiten</FilledButton>
        </RoundedCard>
    </TransitionUp>

    <TransitionUp>
        <RoundedCard color="white" v-if="edit">
            <FormKit type="form" :actions="false" @submit="submit">
                <div class="card-title" ref="editCardTitle">
                    <b>Persönliche Informationen</b>
                    bearbeiten
                </div>

                <div class="mt-4 mb-8">
                    <label>Straße und Hausnummer</label>
                    <FormKit
                        type="text"
                        placeholder="Straße*"
                        v-model="model.street"
                        validation="required:trim"
                        :validation-messages="{ required: 'Straße ist erforderlich.' }"
                    />

                    <label>PLZ und Ort</label>
                    <div class="grid grid-cols-3 gap-5">
                        <div>
                            <FormKit
                                type="number"
                                placeholder="PLZ*"
                                v-model="model.zipCode"
                                validation="required:trim"
                                :validation-messages="{ required: 'Postleitzahl ist erforderlich.' }"
                            />
                        </div>
                        <div class="col-span-2">
                            <FormKit
                                type="text"
                                placeholder="Ort*"
                                v-model="model.city"
                                validation="required:trim"
                                :validation-messages="{ required: 'Ort ist erforderlich.' }"
                            />
                        </div>
                    </div>
                    <label>Telefon</label>
                    <FormKit type="text" placeholder="Telefon" v-model="model.phone" />
                </div>

                <div class="gap-3 flex flex-col md:flex-row">
                    <div>
                        <FilledButton color="primary" class="px-11 w-full md:w-auto" type="submit">
                            Speichern
                        </FilledButton>
                    </div>
                    <div>
                        <OutlineButton color="primary" class="px-11 w-full md:w-auto" @click="edit = false">
                            Abbrechen
                        </OutlineButton>
                    </div>
                </div>
            </FormKit>
        </RoundedCard>
    </TransitionUp>
</template>
