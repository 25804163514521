import { de } from "@formkit/i18n"
import { defaultConfig as formkitDefaultConfig } from "@formkit/vue"

import { generateClasses } from "@formkit/themes"
// @ts-ignore

export default () =>
    formkitDefaultConfig({
        locales: { de },
        locale: "de",
        icons: {
            // ...genesisIcons,
        },
        config: {
            classes: generateClasses({
                global: {
                    input: "input-field !m-0",
                    help: "text-sm opacity-50 italic my-1",
                    message: "text-red-600 my-1",
                    outer: "mb-5",
                    option: "$reset",
                },
            }),
        },
    })
