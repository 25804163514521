<script setup lang="ts">
import type { ClaimableBonusToken } from "@/model/app/claimable-bonus-token"
import { sleep, useHttpClient, VfFeedbackSuccess } from "@/vf"
import { ref } from "vue"
import GridLoader from "vue-spinner/src/GridLoader.vue"
import { useUserStore } from "../stores/user-store"
import FilledButton from "./FilledButton.vue"
import RoundedCard from "./RoundedCard.vue"

const userStore = useUserStore()
const props = defineProps<{ claimableToken: ClaimableBonusToken }>()
const { post } = useHttpClient()

const state = ref<"claimable" | "loading" | "claimed">("claimable")

async function claim() {
    // sleeps are here to make the animation visible, because the api calls are too fast
    state.value = "loading"
    await sleep(500)
    await post(`/wallet/claim/${props.claimableToken.id}`)
    await sleep(500)
    state.value = "claimed"

    await sleep(1000)
    // element will be removed when user store is refreshed
    await userStore.refresh()
}
</script>

<template>
    <RoundedCard :spinner="state !== 'claimable'" color="primary" class="mb-5">
        <template #default>
            <div class="text-4xl font-bold">{{ claimableToken.text }}</div>

            <FilledButton color="white" class="mt-5" @click="claim">Jetzt einlösen</FilledButton>
        </template>
        <template #spinner>
            <div class="flex flex-col items-center" v-if="state === 'loading'">
                <GridLoader color="#E4027E" :loading="true"></GridLoader>
                <div class="text-sm mt-5 text-modal">Bonus-Token werden eingelöst...</div>
            </div>
            <div class="flex flex-col items-center" v-else-if="state === 'claimed'">
                <VfFeedbackSuccess />
                <div class="text-sm text-modal mb-5">Token wurden gutgeschrieben</div>
            </div>
        </template>
    </RoundedCard>
</template>
