<script setup lang="ts">
import { $currency, $smt } from "@/common/util/format"
import RoundedCard from "@/wallet/components/RoundedCard.vue"
import TransitionUp from "@/wallet/components/TransitionUp.vue"
import { useUserStore } from "@/wallet/stores/user-store"
import RecentTransactionsView from "./RecentTransactionsView.vue"

const userStore = useUserStore()

</script>

<template>

    <TransitionUp active-class="delay-[200ms]">
        <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 auto-rows-fr gap-8">
            <RoundedCard color="white">
                <b>Insgesamt</b>
                eingezahlt
                <div class="flex items-start mt-3">
                    <span class="text-4xl font-bold">{{ $currency(userStore.totalEuroCentsPaid!) }}</span>
                    <span class="text-xl ml-2 mt-1">EUR</span>
                </div>
            </RoundedCard>

            <RoundedCard color="white">
                <b>Durchschnittlicher</b>
                Kaufpreis
                <div class="flex items-start mt-3">
                    <span class="text-4xl font-bold">{{ $currency(userStore.averagePricePerTokenInEuroCents!) }}</span>
                    <span class="text-xl ml-2 mt-1">EUR</span>
                </div>
            </RoundedCard>


            <RoundedCard color="white">
                <b>Token</b>
                Guthaben
                <div class="flex items-start mt-3">
                    <span class="text-4xl font-bold">
                        {{ $smt(userStore.tokenBalance!) }}
                    </span>
                    <span class="text-xl ml-2 mt-1">SMT</span>
                </div>
            </RoundedCard>

            <RoundedCard color="white">
                <b>Aktueller Preis</b>
                pro SMT
                <div class="flex items-start mt-3">
                    <span class="text-4xl font-bold">{{ $currency(userStore.currentEuroCentsPerToken!) }}</span>
                    <span class="text-xl ml-2 mt-1">EUR</span>
                </div>
            </RoundedCard>

            <RoundedCard color="white">
                <b>Bonustoken</b>
                erhalten
                <div class="flex items-start mt-3">
                    <span class="text-4xl font-bold">{{ $smt(userStore.bonusTokensReceived!) }}</span>
                    <span class="text-xl ml-2 mt-1">SMT</span>
                </div>
            </RoundedCard>

            <RoundedCard color="white">
                <b>Reservierte</b>
                Token
                <div class="flex items-start mt-3">
                    <span class="text-4xl font-bold">{{ $smt(userStore.reservedTokenBalance!) }}</span>
                    <span class="text-xl ml-2 mt-1">SMT</span>
                </div>
            </RoundedCard>
        </div>
    </TransitionUp>

    <RecentTransactionsView />
</template>
