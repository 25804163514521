<script setup lang="ts">
import { useHttpClient, VfFeedbackError, VfFeedbackSuccess } from "@/vf"
import FilledButton from "@/wallet/components/FilledButton.vue"
import OutlineButton from "@/wallet/components/OutlineButton.vue"
import RoundedCard from "@/wallet/components/RoundedCard.vue"
import type { AxiosError } from "axios"
import { ref } from "vue"
import GridLoader from "vue-spinner/src/GridLoader.vue"
defineEmits<{
    (e: "close"): void
}>()

const currentPassword = ref("")
const newMail = ref("")
const { post } = useHttpClient()
const state = ref<"input" | "loading" | "invalid-password" | "invalid-mail" | "email-used" | "success">("input")

async function submit() {
    state.value = "loading"
    try {
        await post("/wallet/user/change-mail", { currentPassword, newMail })
        state.value = "success"
    } catch (e) {
        const response = (e as AxiosError<{ error: string }>).response

        if (response?.data.error === "invalid-password") {
            state.value = "invalid-password"
        } else {
            state.value = "invalid-mail"
        }
    }
}
</script>

<template>
    <RoundedCard color="white" class="!p-0 overflow-y-hidden" :spinner="state !== 'input'">
        <template #default>
            <form @submit.prevent="submit">
                <div class="card-title">
                    <b>E-Mailadresse</b>
                    ändern
                </div>

                <div>
                    Bitte geben Sie zu Ihrer Sicherheit Ihr aktuelles Passwort ein. Nach dem Speichern Ihrer neuen
                    E-Mailadresse erhalten Sie eine E-Mail mit einem Link um Ihre neue E-Mailadresse zu übernehmen.
                </div>

                <div class="mt-4 mb-8">
                    <input
                        type="password"
                        placeholder="Aktuelles Passwort"
                        class="input-field"
                        v-model="currentPassword"
                        autocomplete="current-password"
                    />
                    <input
                        type="email"
                        placeholder="Neue E-Mailadresse"
                        class="input-field"
                        v-model="newMail"
                        autocomplete="email"
                    />
                </div>

                <div class="">
                    <FilledButton color="primary" class="px-11" type="submit">Speichern</FilledButton>
                    <OutlineButton color="primary" class="px-11" @click="$emit('close')">Abbrechen</OutlineButton>
                </div>
            </form>
        </template>
        <template #spinner>
            <div class="flex flex-col items-center" v-if="state === 'loading'">
                <GridLoader color="#E4027E" :loading="true"></GridLoader>
                <div class="text-sm mt-5 text-modal">E-Mailadresse wird geändert</div>
            </div>
            <div class="flex flex-col items-center" v-else-if="state === 'invalid-password'">
                <VfFeedbackError />
                <div class="text-sm text-modal mb-3">Eingegebenes Passwort ist falsch</div>
                <div>
                    <FilledButton color="primary" @click="state = 'input'">OK</FilledButton>
                </div>
            </div>
            <div class="flex flex-col items-center" v-else-if="state === 'invalid-mail'">
                <VfFeedbackError />
                <div class="text-sm text-modal mb-3">E-Mailadresse ist ungültig oder wird bereits benutzt</div>
                <div>
                    <FilledButton color="primary" @click="state = 'input'">OK</FilledButton>
                </div>
            </div>
            <div class="flex flex-col items-center" v-else-if="state === 'success'">
                <VfFeedbackSuccess />
                <div class="text-sm text-modal mb-5">E-Mail wurde geändert, bitte prüfen Sie Ihr E-Mail-Postfach</div>
                <div>
                    <FilledButton color="primary" @click="$emit('close')">Schließen</FilledButton>
                </div>
            </div>
        </template>
    </RoundedCard>
</template>
