<script setup lang="ts">
import { useHttpClient, VfFeedbackError, VfFeedbackSuccess } from "@/vf"

import { useAsyncState } from "@vueuse/core"
import { ref } from "vue"
import GridLoader from "vue-spinner/src/GridLoader.vue"
import FilledBackgroundContainer from "../components/FilledBackgroundContainer.vue"
import FilledButton from "../components/FilledButton.vue"
import OverlappingElement from "../components/OverlappingElement.vue"
import RoundedCard from "../components/RoundedCard.vue"

const props = defineProps<{
    code: string
}>()

const http = useHttpClient()
const success = ref(false)
const isVerified = ref(false)
const { isLoading } = useAsyncState(async () => {
    try {
        isVerified.value = (await http.post<{ isVerified: boolean }>("/wallet/verify/email", { code: props.code })).data.isVerified
        success.value = true
    } catch (e) {
        console.error(e)
    }
}, undefined)
</script>

<template>
    <FilledBackgroundContainer class="grid w-full h-full">
        <OverlappingElement class="w-1/2 mx-auto col-start-1 col-span-1 row-start-1 row-end-1">
            <template #under></template>
            <template #default>
                <RoundedCard color="modal" :back-button="false">
                    <template v-if="isLoading">
                        <div class="flex flex-col items-center">
                            <GridLoader color="#E4027E" :loading="true" />
                            <div class="mt-5 text-center">E-Mail wird verifiziert...</div>
                        </div>
                    </template>
                    <template v-else-if="success">
                        <VfFeedbackSuccess />
                        <div class="text-center">
                            <div class="mb-10">
                                Die Aktivierung war erfolgreich.
                                <span v-if="isVerified">Sie können sich jetzt einloggen.</span>
                                <span v-else>Sobald Sie freigeschaltet wurden können Sie sich einloggen.</span>
                            </div>

                            <RouterLink :to="{ name: 'login' }">
                                <FilledButton>Zum Login</FilledButton>
                            </RouterLink>
                        </div>
                    </template>
                    <template v-else>
                        <VfFeedbackError />
                        <div class="text-center">
                            <div class="mb-10">
                                Da ist etwas schief gelaufen. Eventuell ist der Link nicht mehr gültig.
                            </div>

                            <RouterLink :to="{ name: 'login' }">
                                <FilledButton>Zum Login</FilledButton>
                            </RouterLink>
                        </div>
                    </template>
                </RoundedCard>
            </template>
        </OverlappingElement>
    </FilledBackgroundContainer>
</template>
