<script setup lang="ts"></script>

<template>
    <div class="container mx-auto my-10 legal-page">
        <h1 class="!mb-2">
            Bedingungen für den Erwerb von Waren, Dienstleistungen und sonstigen Kaufgegenständen von Scoopas
        </h1>
        <p class="text-sm opacity-50 !mb-8">Version 1.0 Stand: 13.07.2023</p>

        <h2>1. Geltungsbereich und Allgemeines</h2>
        <ol>
            <li>
                <p>
                    Diese Bedingungen für den Erwerb von Waren, Dienstleistungen und sonstigen Kaufgegenständen von
                    Scoopas („<strong>Kaufbedingungen</strong>“) regeln die Veräußerung von Waren, Dienstleistungen und
                    sonstigen Kaufgegenständen („<strong>Kaufgegenstand</strong>“) über die Webseite durch Scoopas.
                </p>
            </li>
            <li>
                <p>
                    Die Definitionen in den Kaufbedingungen haben dieselbe Bedeutung wie die Definitionen in den
                    Allgemeinen Nutzungsbedingungen und Tokenbedingungen, sofern in den Kaufbedingungen nichts anderes
                    geregelt ist.
                </p>
            </li>
            <li>
                <p>
                    Für den Erwerb von Kaufgegenständen ist eine vorherige Registrierung und Anmeldung zu einem
                    Benutzerkonto erforderlich.
                </p>
            </li>
            <li>
                <p>Kaufgegenstände können ausschließlich mit Scoopas Coins erworben werden.</p>
            </li>
            <li>
                <p>
                    Scoopas akzeptiert keine abweichenden Bedingungen des Nutzers. Dies gilt auch, wenn Scoopas der
                    Einbeziehung nicht ausdrücklich widerspricht.
                </p>
            </li>
        </ol>
        <h2>2. Erwerbsprozess und Vertragsabschlüsse</h2>
        <ol>
            <li>
                <p>
                    Die Präsentation von Kaufgegenständen auf der Plattform stellt kein bindendes Angebot zum Abschluss
                    eines Vertrags dar.
                </p>
            </li>
            <li>
                <p>
                    Der Nutzer kann auf der Webseite durch Klicken Kaufgegenstände auswählen und in den Warenkorb
                    einfügen. Mit Klick auf den Warenkorb wird der Nutzer auf eine Bestellmaske, die Informationen zu
                    den ausgewählten Kaufgegenständen enthält, weitergeleitet. Nach Eingabe der angeforderten
                    Informationen kann der Nutzer seine eingegebenen Informationen überprüfen und ggfs. korrigieren. Mit
                    Anklicken der Schaltfläche „zahlungspflichtig kaufen“ gibt der Nutzer verbindlich ein Angebot ab.
                </p>
            </li>
            <li>
                <p>
                    Scoopas wird den Zugang des Angebots unverzüglich per E-Mail oder sonstige Benachrichtigung
                    bestätigen. In einer solchen E-Mail bzw. Benachrichtigung liegt noch keine verbindliche Annahme des
                    Angebots, es sei denn, darin wird neben der Bestätigung des Zugangs zugleich die Annahme erklärt.
                    Ein Vertrag kommt erst zustande, wenn Scoopas das Angebot durch eine Annahmeerklärung annimmt.
                </p>
            </li>
        </ol>
        <h2>3. Lieferbedingungen</h2>
        <ol>
            <li>
                Scoopas ist im Falle von physischen Kaufgegenständen zu Teillieferungen berechtigt, soweit dies für den
                Nutzer zumutbar ist.
            </li>
            <li>
                Die Lieferfrist für physische Kaufgegenständen beträgt circa 7 Werktage, soweit nichts anderes
                vereinbart wurde. Digitale Kaufgegenstände werden unverzüglich nach Zahlungseingang gesendet. Bezüglich
                erworbener Dienstleistungen finden sich Informationen zur Inanspruchnahme in der Bestellmaske bzw.
                Nachricht, mit der die Annahme des Angebots des Nutzers erklärt wird. Die jeweilige Lieferfrist beginnt
                mit Vertragsschluss.
            </li>
        </ol>
        <h2>4. Zahlungsbedingungen</h2>
        <ol>
            <li>
                Als Zahlungsmittel stehen Banküberweisung und Scoopas Token zur Verfügung. Nach der Einführung von
                Scoopas Coins werden auch diese als Zahlungsmittel akzeptiert.
            </li>
            <li>Sofern nichts anderes vereinbart ist, ist der jeweilige Kaufpreis sofort fällig.</li>
            <li>
                Der Nutzer darf ein Zurückbehaltungsrecht nur dann ausüben, wenn der Gegenanspruch aus demselben Vertrag
                herrührt.
            </li>
        </ol>
        <h2>5. Gewährleistung und Haftung</h2>
        <ol>
            <li>Scoopas haftet für Sach- oder Rechtsmängel nach den geltenden gesetzlichen Vorschriften.</li>
            <li>
                Scoopas haftet dem Nutzer gegenüber in allen sonstigen Fällen vertraglicher und außervertraglicher
                Haftung bei Vorsatz und grober Fahrlässigkeit nach Maßgabe der gesetzlichen Bestimmungen auf
                Schadensersatz oder Ersatz vergeblicher Aufwendungen.
            </li>
            <li>
                Soweit Scoopas nicht nach den Bestimmungen des ersten und zweiten Absatzes uneingeschränkt haftet, ist
                im Falle einer auf einfacher Fahrlässigkeit beruhenden Verletzung einer wesentlichen Vertragspflicht die
                Haftung von Scoopas auf Schadens- oder Aufwendungsersatz auf den typischerweise vorhersehbaren Schaden
                beschränkt. Wesentliche Vertragspflichten sind Pflichten, deren Erfüllung die ordnungsgemäße
                Durchführung der jeweiligen Vereinbarungen überhaupt erst ermöglicht und auf deren Erfüllung Sie
                regelmäßig vertrauen und auch vertrauen dürfen (Kardinalpflichten).
            </li>
            <li>
                Die Haftung von Scoopas aufgrund der anwendbaren produkthaftungsrechtlichen Vorschriften bleibt
                unberührt.
            </li>
            <li>Über die vorgenannten Fälle hinaus ist eine Haftung von Scoopas ausgeschlossen.</li>
        </ol>
        <h2>6. Widerrufsrecht</h2>
        <p>
            Wenn der Nutzer Verbraucher gemäß § 13 BGB ist, steht dem Nutzer nach Maßgabe der folgenden Regelungen ein
            Widerrufsrecht bezüglich des Vertrags zu. Gemäß § 312g Abs. 2 Nr. 9 BGB besteht kein Widerrufsrecht, wenn
            der Nutzer eine Eintrittskarte für eine bzw. ein Zutrittsrecht für einer Veranstaltung erworben hat.
        </p>
        <ol>
            <li>
                <strong>Widerrufsbelehrung bei Kauf von physischen Kaufgegenständen</strong>
            </li>
        </ol>
        <div class="border border-secondary p-3 my-3">
            <p align="center">
                <strong>Widerrufsbelehrung</strong>
            </p>
            <p>
                <strong>Widerrufsrecht</strong>
            </p>
            <p>Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.</p>
            <p>
                Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag an dem Sie oder ein von Ihnen benannter Dritter, der
                nicht der Beförderer ist, die letzte Ware in Besitz genommen haben bzw. hat.
            </p>
            <p>
                Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (SCOOPAS GmbH, Charlottenstr. 75, 40210 Düsseldorf,
                Germany, E-Mail: widerruf@scoopas.com) mittels einer eindeutigen Erklärung (z. B. ein mit der Post
                versandter Brief oder eine E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie
                können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist. Sie
                können das Muster-Widerrufsformular oder eine andere eindeutige Erklärung auch auf unserer Webseite
                https://wallet.scoopas.io elektronisch ausfüllen und übermitteln. Machen Sie von dieser Möglichkeit
                Gebrauch, so werden wir Ihnen unverzüglich (z. B. per E-Mail) eine Bestätigung über den Eingang eines
                solchen Widerrufs übermitteln.
            </p>
            <p>
                Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des
                Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
            </p>
            <p>
                <strong>Folgen des Widerrufs</strong>
            </p>
            <p>
                Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben,
                einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie
                eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben),
                unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über
                Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe
                Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen
                wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung
                Entgelte berechnet.
            </p>
            <p>
                Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den
                Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere
                Zeitpunkt ist.
            </p>
            <p>
                Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag, an dem
                Sie uns über den Widerruf dieses Vertrags unterrichten, an uns zurückzusenden oder zu übergeben. Die
                Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden. Sie tragen die
                unmittelbaren Kosten der Rücksendung der Waren.
            </p>
            <p>
                Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen zur
                Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit
                ihnen zurückzuführen ist.
            </p>
        </div>
        <ol start="2">
            <li>
                <strong>
                    Widerrufsbelehrung bei Kauf von nicht-physischen Kaufgegenständen (z.B. digitalen Kaufgegenständen)
                    und Dienstleistungen
                </strong>
            </li>
        </ol>
        <div class="border border-secondary p-3 my-3">
            <p align="center">
                <strong>Widerrufsbelehrung</strong>
            </p>
            <p>
                <strong>Widerrufsrecht</strong>
            </p>
            <p>Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.</p>
            <p>Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses</p>
            <p>
                Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (SCOOPAS GmbH, Charlottenstr. 75, 40210 Düsseldorf,
                Germany, E-Mail: widerruf@scoopas.com) mittels einer eindeutigen Erklärung (z. B. ein mit der Post
                versandter Brief oder eine E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie
                können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist. Sie
                können das Muster-Widerrufsformular oder eine andere eindeutige Erklärung auch auf unserer Webseite
                https://wallet.scoopas.io elektronisch ausfüllen und übermitteln. Machen Sie von dieser Möglichkeit
                Gebrauch, so werden wir Ihnen unverzüglich (z. B. per E-Mail) eine Bestätigung über den Eingang eines
                solchen Widerrufs übermitteln.
            </p>
            <p>
                Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des
                Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
            </p>
            <p>
                <strong>Folgen des Widerrufs</strong>
            </p>
            <p>
                Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben,
                einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie
                eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben),
                unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über
                Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe
                Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen
                wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung
                Entgelte berechnet.
            </p>
            <p>
                Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen soll, so haben Sie uns
                einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der
                Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits erbrachten
                Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.
            </p>
        </div>
        <ol start="3">
            <li>
                <strong>Muster-Widerrufsformular</strong>
            </li>
        </ol>
        <div class="border border-secondary p-3 my-3">
            <p>
                <strong>Muster-Widerrufsformular</strong>
            </p>
            <p>
                (Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es
                zurück.)
            </p>
            <p>- An SCOOPAS GmbH, Charlottenstr. 75, 40210 Düsseldorf, Germany, E-Mail: widerruf@scoopas.com:</p>
            <p>
                - Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf der
                folgenden Waren (*)/ die Erbringung der folgenden Dienstleistung(*)
            </p>
            <p>- Bestellt am (*)/erhalten am (*)</p>
            <p>- Name des/der Verbraucher(s)</p>
            <p>- Anschrift des/der Verbraucher(s)</p>
            <p>Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)</p>
            <p>- Datum</p>
            <p>(*) Unzutreffendes streichen</p>
        </div>
        <h2>7. Schlussbestimmungen</h2>
        <ol>
            <li>
                Soweit nach dem zwingenden Recht eines Mitgliedstaates der Europäischen Union oder eines anderes anderen
                Landes nicht etwas anderes vorgeschrieben ist, unterliegen die Vereinbarungen (und etwaige aus oder in
                Zusammenhang damit entstehenden außervertraglichen Streitigkeiten/Ansprüche) deutschem Recht, unter
                Ausschluss der Kollisionsnormen. Verbraucher können sich jederzeit auf günstigere Regelungen ihres
                Heimatrechts berufen.
            </li>
            <li>
                Unter folgendem Link kann die Plattform der Europäischen Kommission zur Online-Streitbeilegung (OS) für
                Privatpersonen:
                <a href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a>
                genutzt werden. Scoopas nimmt bei Verträgen, für die die vorliegenden Nutzungsbedingungen gelten, nicht
                an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil.
            </li>
            <li>
                Soweit in den Vereinbarungen nicht anders angegeben, berührt die Unwirksamkeit oder
                Nichtdurchsetzbarkeit einer Bestimmung der Vereinbarungen oder eines Teils davon, unabhängig vom Grund
                der Unwirksamkeit oder Nichtdurchsetzbarkeit, nicht die Wirksamkeit oder Durchsetzbarkeit der übrigen
                Bestimmungen der Vereinbarungen.
            </li>
            <li>Vertragssprache ist deutsch.</li>
        </ol>
    </div>
</template>
