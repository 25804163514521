<script setup lang="ts">
import { useUserStore } from "../stores/user-store"
import ClaimableToken from "./ClaimableToken.vue"

const userStore = useUserStore()
</script>

<template>
    <TransitionGroup
        leave-active-class="transition duration-500"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-10 opacity-0"
    >
        <template v-for="claimable of userStore.claimableTokens ?? []" :key="claimable.id!">
            <ClaimableToken :claimable-token="claimable"></ClaimableToken>
        </template>
    </TransitionGroup>
</template>
