import type { UserStoreData } from "@/model/app/user-store-data"
import { useEnsureLoaded, useHttpClient } from "@/vf"
import { defineStore } from "pinia"
import { reactive, ref, toRefs, watch } from "vue"
import { useRouter } from "vue-router"

export const useUserStore = defineStore("user", () => {
    const { get, post } = useHttpClient()
    const router = useRouter()
    const { refresh, ensureLoaded } = useEnsureLoaded("User store", refreshFn)

    const authenticated = ref(false)
    const data = reactive<UserStoreData>({
        number: undefined,
        company: undefined,
        fullName: undefined,
        birthdate: undefined,
        bonusTokensReceived: undefined,
        city: undefined,
        currentEuroCentsPerToken: undefined,
        email: undefined,
        firstName: undefined,
        lastName: undefined,
        reservedTokenBalance: undefined,
        street: undefined,
        tokenBalance: undefined,
        totalEuroCentsPaid: undefined,
        zipCode: undefined,
        phone: undefined,
        isVerified: undefined,
        tokenBalanceValueInEuroCents: undefined,
        agentMailAddress: undefined,
        acceptedLegalTexts: undefined,
        claimableTokens: undefined,
        averagePricePerTokenInEuroCents: undefined,
    })

    async function refreshFn(): Promise<void> {
        try {
            const response = await get<UserStoreData>("/wallet/user")

            authenticated.value = true

            for (const key of Object.keys(data) as Array<keyof UserStoreData>) {
                // for some reason typescript refuses to accept that the types WILL match...
                // eslint-disable-next-line no-extra-semi
                ;(data as any)[key] = response.data[key]
            }
        } catch {
            clear()
        }
    }

    function clear() {
        authenticated.value = false
        for (const key of Object.keys(data) as (keyof UserStoreData)[]) {
            data[key] = undefined
        }
    }

    async function logout() {
        await post("/wallet/logout")
        clear()
        router.push({ name: "home" })
    }

    watch(authenticated, () => console.log("Authenticated:", authenticated.value))
    refresh()

    return { authenticated, ...toRefs(data), refresh, ensureLoaded, clear, logout } as const
})
