import { autoAnimatePlugin } from "@formkit/auto-animate/vue"
import { plugin as formkit } from "@formkit/vue"
import { createPinia } from "pinia"
import { createApp } from "vue"

import App from "./App.vue"
import createFormkitConfig from "./formkit.config"
import router from "./router"

import { createI18n } from "vue-i18n"
import "./assets/main.css"

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(formkit, createFormkitConfig())
app.use(autoAnimatePlugin)

const i18n = createI18n({})

app.use(i18n)

app.mount("#app")
