<script setup lang="ts">
import { useUserStore } from "@/wallet/stores/user-store"
import UserIcon from "~icons/mdi/user"

const userStore = useUserStore()
</script>

<template>
    <nav class="gap-10 items-center flex flex-col lg:flex-row py-10 md:py-0">
        <a :href="'mailto:' + (userStore.agentMailAddress ?? 'support@scoopas.io')">Kontakt</a>

        <RouterLink class="md:hidden" v-if="userStore.authenticated" :to="{ name: 'dashboard' }">Dashboard</RouterLink>
        <RouterLink class="md:hidden" v-if="userStore.authenticated" :to="{ name: 'my-profile' }">
            Mein Profil
        </RouterLink>
        <RouterLink class="md:hidden" v-if="userStore.authenticated" :to="{ name: 'security-settings' }">
            Sicherheit
        </RouterLink>

        <RouterLink
            v-if="!userStore.authenticated"
            :to="{ name: 'login' }"
            class="border rounded-full px-3 py-2 border-white hover:border-slate-300"
        >
            Einloggen
        </RouterLink>
        <RouterLink
            v-if="userStore.authenticated"
            :to="{ name: 'dashboard' }"
            class="flex items-center gap-2 border rounded-full px-3 py-2 border-white hover:border-slate-300"
        >
            <span>Meine Wallet</span>
            <div class="h-6 w-6 rounded-full bg-white text-secondary grid place-items-center">
                <UserIcon />
            </div>
        </RouterLink>

        <button class="md:hidden" v-if="userStore.authenticated" @click="userStore.logout">Sicher ausloggen</button>
    </nav>
</template>
