<script setup lang="ts">
import { useChangePassword } from "@/common/composables/useChangePassword"
import { sleep, useHttpClient } from "@/vf"
import FilledButton from "@/wallet/components/FilledButton.vue"
import OverlappingElement from "@/wallet/components/OverlappingElement.vue"
import RoundedCard from "@/wallet/components/RoundedCard.vue"
import { useUserStore } from "@/wallet/stores/user-store"
import { ref } from "vue"
import { useRouter } from "vue-router"

const props = defineProps<{ token?: string }>()

const { newPassword, confirmPassword, isValid, passwordsMatch } = useChangePassword()

const state = ref<"input" | "fail" | "success">("input")

const { postBg } = useHttpClient()
const userStore = useUserStore()
const router = useRouter()

async function submit() {
    try {
        await postBg("/wallet/user/change-password", { newPassword, token: props.token })

        if (props.token) {
            // password reset via token --> user needs to log in
            state.value = "success"
            return
        }

        // user is already logged in and used a temporary password --> refresh user store and redirect to dashboard
        await userStore.refresh()
        if (userStore.authenticated) {
            await router.push({ name: "dashboard" })
            return
        }
    } catch (e) {
        state.value = "fail"
        await sleep(2000)
        state.value = "input"
    }
}
</script>

<template>
    <Transition :duration="500" appear>
        <OverlappingElement class="w-3/4 lg:w-2/3 xl:w-1/2 mx-auto" v-if="state !== 'success'">
            <template #under><img src="@/wallet/assets/icons/lock.svg" /></template>
            <template #default>
                <RoundedCard color="modal" :class="{ 'animate-shake': state === 'fail' }">
                    <form class="" @submit.prevent="submit">
                        <div class="card-title">
                            <b>Passwort</b>
                            zurücksetzen
                        </div>

                        <p>Bitte legen Sie ein neues Passwort fest um fortzufahren.</p>

                        <div
                            class="text-red-500 mt-2 opacity-0 transition translate-y-5 delay-500"
                            :class="{ 'opacity-100 !translate-y-0': state === 'fail' }"
                        >
                            Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.
                        </div>

                        <div class="mt-5 mb-10">
                            <input
                                type="password"
                                v-model="newPassword"
                                placeholder="Neues Passwort"
                                class="input-field"
                            />
                            <input
                                type="password"
                                v-model="confirmPassword"
                                placeholder="Neues Passwort wiederholen"
                                class="input-field"
                            />
                            <div
                                class="text-red-600 transition translate-y-5 opacity-0 pl-8"
                                :class="{ '!translate-y-0 opacity-100': !passwordsMatch }"
                            >
                                Passwörter stimmen nicht überein
                            </div>
                        </div>

                        <FilledButton color="primary" class="w-full" type="submit" :disabled="!isValid">
                            Passwort ändern
                        </FilledButton>
                    </form>
                </RoundedCard>
            </template>
        </OverlappingElement>

        <OverlappingElement class="w-3/4 lg:w-2/3 xl:w-1/2 mx-auto" v-else-if="state === 'success'">
            <template #under><img src="@/wallet/assets/icons/lock.svg" /></template>
            <template #default>
                <RoundedCard color="modal">
                    <div class="card-title">
                        <b>Passwort</b>
                        zurücksetzen
                    </div>

                    <p class="mb-5">
                        Ihr Passwort wurde erfolgreich geändert. Bitte loggen Sie sich mit Ihrem neuen Passwort ein.
                    </p>

                    <RouterLink :to="{ name: 'login' }">
                        <FilledButton>Zum Login</FilledButton>
                    </RouterLink>
                </RoundedCard>
            </template>
        </OverlappingElement>
    </Transition>
</template>
