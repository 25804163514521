<script setup lang="ts">
import { $currency, $smt, isNumericKey } from "@/common/util/format"
import { sleep, useHttpClient, VfFeedbackError, VfFeedbackSuccess } from "@/vf"
import CustomRadio from "@/wallet/components/CustomRadio.vue"
import FilledButton from "@/wallet/components/FilledButton.vue"
import OutlineButton from "@/wallet/components/OutlineButton.vue"
import RoundedCard from "@/wallet/components/RoundedCard.vue"
import TransitionUp from "@/wallet/components/TransitionUp.vue"
import { useUserStore } from "@/wallet/stores/user-store"
import { computed, ref } from "vue"
import GridLoader from "vue-spinner/src/GridLoader.vue"

type SmtOrEur = "smt" | "eur"

const userStore = useUserStore()
const { post } = useHttpClient()

const unit = ref<SmtOrEur>("smt")
const amount = ref<number | null>(null)
const convertedUnit = computed(() => (unit.value === "smt" ? "eur" : "smt"))
const convertedAmount = computed<number>(() => {
    const _amount = amount.value ?? 0

    if (unit.value === "smt") {
        return _amount * userStore.currentEuroCentsPerToken!
    } else {
        return (_amount * 100) / userStore.currentEuroCentsPerToken!
    }
})

const state = ref<"input" | "pending" | "progress" | "success" | "error">("input")

async function submit(action: "buy" | "reserve") {
    if (!amount.value || amount.value <= 0) {
        return
    }

    try {
        let scaledAmount = amount.value ?? 0 * (unit.value === "eur" ? 100 : 1)
        if (unit.value === "eur") {
            scaledAmount *= 100
        }

        state.value = "pending"
        await post("/wallet/buy", { action, amount: scaledAmount, unit })
        await sleep(2000)
        state.value = "progress"
        await userStore.refresh()
        await sleep(2000)
        state.value = "success"
    } catch (e) {
        console.error(e)
        state.value = "error"
    }
}

const consent1 = ref(false)
const consent2 = ref(false)
const consentAll = computed(() => consent1.value && consent2.value)
</script>

<template>
    <TransitionUp>
        <RoundedCard class="!p-0 overflow-y-hidden" :spinner="state !== 'input'">
            <template #default>
                <div class="card-title">
                    <b>SMT</b>
                    kaufen
                </div>

                <div>Klicken Sie auf eine Einheit, um wahlweise die Menge in EUR oder SMT einzugeben.</div>

                <div class="flex gap-5 mt-6 mb-3 flex-col md:flex-row md:items-center">
                    <div class="grow">
                        <input
                            class="input-field !mb-0"
                            :placeholder="`Menge in ${unit.toUpperCase()}`"
                            @keypress="isNumericKey"
                            v-model.number="amount"
                        />
                    </div>
                    <div class="flex">
                        <div>
                            <CustomRadio value="smt" v-model="unit">SMT</CustomRadio>
                        </div>
                        <div>
                            <CustomRadio value="eur" v-model="unit">EUR</CustomRadio>
                        </div>
                    </div>
                </div>

                <div class="text-placeholder ml-8">
                    entspricht
                    {{ convertedUnit === "smt" ? $smt(convertedAmount.toString()) : $currency(convertedAmount) }}
                    {{ convertedUnit.toUpperCase() }}
                </div>

                <label class="block mb-3 mt-8 py-3">
                    <input type="checkbox" v-model="consent1" />
                    <span>
                        Hiermit akzeptiere ich die
                        <RouterLink :to="{ name: 'token-disclaimer' }" class="text-primary font-bold">
                            Tokenbedingungen
                        </RouterLink>
                        von Scoopas.
                    </span>
                </label>

                <label class="block mt-3 py-3">
                    <input type="checkbox" v-model="consent2" />
                    <span>
                        Hiermit bestätige ich die
                        <RouterLink :to="{ name: 'purchase-conditions' }" class="text-primary font-bold">
                            Kaufbedingungen
                        </RouterLink>
                        von Scoopas.
                    </span>
                </label>

                <div class="mt-8 flex gap-2 md:gap-5 flex-col md:flex-row">
                    <div>
                        <FilledButton
                            color="primary"
                            class="md:px-16 w-full md:w-auto"
                            @click="submit('buy')"
                            :disabled="!consentAll"
                        >
                            Kaufantrag bestätigen
                        </FilledButton>
                    </div>
                    <div>
                        <OutlineButton
                            color="primary"
                            class="w-full md:w-auto"
                            @click="submit('reserve')"
                            :disabled="!consentAll"
                        >
                            Token reservieren
                        </OutlineButton>
                    </div>
                </div>
            </template>
            <template #spinner>
                <div class="flex flex-col items-center" v-if="state === 'pending' || state === 'progress'">
                    <GridLoader color="#E4027E" :loading="true"></GridLoader>
                    <div class="text-sm mt-5 text-modal" v-if="state === 'pending'">
                        Scoopas Music Token werden reserviert...
                    </div>
                    <div class="text-sm mt-5 text-modal" v-else-if="state === 'progress'">
                        Bestätigung wird erzeugt...
                    </div>
                </div>

                <div v-else-if="state === 'success'">
                    <Transition
                        appear
                        enter-active-class="transition duration-300"
                        enter-from-class="scale-0 opacity-0"
                        enter-to-class="scale-100 opacity-100"
                    >
                        <div class="flex flex-col items-center">
                            <div class="">
                                <VfFeedbackSuccess />
                            </div>
                            <div class="text-sm -mt-3 mb-8 text-modal text-center">
                                Transaktion erfolgreich. Bitte prüfen Sie Ihr E-Mail-Postfach.
                            </div>
                            <div>
                                <RouterLink :to="{ name: 'dashboard' }">
                                    <FilledButton color="primary">Zum Dashboard</FilledButton>
                                </RouterLink>
                            </div>
                        </div>
                    </Transition>
                </div>

                <div v-else-if="state === 'error'">
                    <Transition
                        appear
                        enter-active-class="transition duration-300"
                        enter-from-class="scale-0 opacity-0"
                        enter-to-class="scale-100 opacity-100"
                    >
                        <div class="flex flex-col items-center text-center">
                            <div class="">
                                <VfFeedbackError />
                            </div>
                            <div class="text-sm -mt-3 mb-8 text-modal">
                                Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.
                            </div>
                            <div>
                                <FilledButton color="primary" @click="state = 'input'">Schließen</FilledButton>
                            </div>
                        </div>
                    </Transition>
                </div>
            </template>
        </RoundedCard>
    </TransitionUp>
</template>
