<script setup lang="ts">
import { useHttpClient, VfFeedbackSuccess } from "@/vf"
import { FormKit, FormKitMessages } from "@formkit/vue"
import { AxiosError } from "axios"
import { reactive, ref } from "vue"
import GridLoader from "vue-spinner/src/GridLoader.vue"
import CustomCheckbox from "../components/CustomCheckbox.vue"
import FilledBackgroundContainer from "../components/FilledBackgroundContainer.vue"
import FilledButton from "../components/FilledButton.vue"
import OverlappingElement from "../components/OverlappingElement.vue"
import RoundedCard from "../components/RoundedCard.vue"

const model = reactive({
    agentCode: "",
    salutation: "",
    title: "",
    firstName: "",
    lastName: "",
    company: "",
    birthdate: "",
    street: "",
    zipCode: "",
    city: "",
    email: "",
    phone: "",
    plainPassword: "",
})

const step = ref<1 | 2 | 3 | 4>(1)
const form1 = ref()
const form2 = ref()
const form3 = ref()
const submitLoading = ref(false)
const errors = ref<{ [key: string]: string[] }>({})
const consentTos = ref(false)
const consentDataPrivacy = ref(false)

async function submit() {
    const http = useHttpClient()
    try {
        submitLoading.value = true
        await http.post("/wallet/register", model)
        step.value = 4
    } catch (e) {
        if (e instanceof AxiosError) {
            errors.value = e.response?.data || {}
            for (const errorKey of Object.keys(errors.value)) {
                if (["agentCode", "salutation", "firstName", "lastName", "birthdate"].includes(errorKey)) {
                    step.value = 1
                    break
                } else if (["phone", "street", "zipCode", "city"].includes(errorKey)) {
                    step.value = 2
                    break
                }
            }
        }
    }

    submitLoading.value = false
}
</script>

<template>
    <FilledBackgroundContainer class="grid w-full h-full">
        <Transition :duration="500" appear>
            <OverlappingElement
                class="w-3/4 lg:w-2/3 xl:w-1/2 mx-auto col-start-1 col-span-1 row-start-1 row-end-1"
                v-if="step === 1"
            >
                <template #under>1</template>
                <template #default>
                    <FormKit type="form" @submit="step = 2" :actions="false" ref="form1" :classes="{ form: 'grid' }">
                        <RoundedCard back-link="/" color="modal">
                            <template #title>Registrierung</template>
                            <template #default>
                                <div class="italic font-bold text-lg my-4">Persönliche Angaben</div>

                                <FormKit
                                    :errors="errors.agentCode"
                                    type="text"
                                    placeholder="Agentur-Code*"
                                    v-model="model.agentCode"
                                    validation="required:trim"
                                    :validation-messages="{ required: 'Agentur-Code ist erforderlich.' }"
                                    help="Der Code, den Sie von Ihrem Agenten erhalten haben."
                                />

                                <div class="flex gap-4">
                                    <FormKit
                                        type="select"
                                        placeholder="Anrede*"
                                        v-model="model.salutation"
                                        :errors="errors.salutation"
                                        validation="trim"
                                        :options="['', 'Herr', 'Frau']"
                                    />
                                    <FormKit type="text" placeholder="Titel (optional)" v-model="model.title" />
                                </div>
                                <FormKit
                                    type="text"
                                    placeholder="Firma (optional)"
                                    v-model="model.company"
                                    :errors="errors.company"
                                    validation="trim"
                                />
                                <FormKit
                                    type="text"
                                    placeholder="Vorname*"
                                    v-model="model.firstName"
                                    :errors="errors.firstName"
                                    validation="required:trim"
                                    :validation-messages="{ required: 'Vorname ist erforderlich.' }"
                                />
                                <FormKit
                                    type="text"
                                    placeholder="Nachname*"
                                    v-model="model.lastName"
                                    :errors="errors.lastName"
                                    validation="required:trim"
                                    :validation-messages="{ required: 'Nachname ist erforderlich.' }"
                                />

                                <FormKit
                                    type="date"
                                    placeholder="Geburtstag*"
                                    validation="required:trim"
                                    :validation-messages="{ required: 'Geburtstag ist erforderlich.' }"
                                    v-model="model.birthdate"
                                    :errors="errors.birthdate"
                                />

                                <FormKitMessages :node="form1?.node" />
                                <FilledButton class="w-full mt-3" type="submit">Weiter</FilledButton>
                            </template>
                        </RoundedCard>
                    </FormKit>
                </template>
            </OverlappingElement>

            <OverlappingElement
                class="w-3/4 lg:w-2/3 xl:w-1/2 mx-auto col-start-1 col-span-1 row-start-1 row-end-1"
                v-else-if="step === 2"
            >
                <template #under>2</template>
                <template #default>
                    <RoundedCard :back-emit="true" @back="step = 1" color="modal">
                        <template #title>Registrierung</template>
                        <template #default>
                            <FormKit
                                type="form"
                                @submit="step = 3"
                                :actions="false"
                                ref="form2"
                                :classes="{ form: 'grid' }"
                            >
                                <div class="italic font-bold text-lg my-4">Persönliche Angaben</div>

                                <FormKit
                                    type="text"
                                    placeholder="Telefon*"
                                    v-model="model.phone"
                                    :errors="errors.phone"
                                    validation="required:trim"
                                    :validation-messages="{ required: 'Telefonnummer ist erforderlich.' }"
                                />

                                <FormKit
                                    type="text"
                                    placeholder="Straße*"
                                    v-model="model.street"
                                    :errors="errors.street"
                                    validation="required:trim"
                                    :validation-messages="{ required: 'Straße ist erforderlich.' }"
                                />

                                <div class="flex gap-4">
                                    <FormKit
                                        type="number"
                                        placeholder="PLZ*"
                                        v-model="model.zipCode"
                                        :errors="errors.zipCode"
                                        validation="required:trim"
                                        :validation-messages="{ required: 'Postleitzahl ist erforderlich.' }"
                                    />
                                    <FormKit
                                        type="text"
                                        placeholder="Ort*"
                                        v-model="model.city"
                                        :errors="errors.city"
                                        validation="required:trim"
                                        :validation-messages="{ required: 'Ort ist erforderlich.' }"
                                    />
                                </div>

                                <FormKitMessages :node="form2?.node" />
                                <FilledButton class="w-full mt-3" type="submit">Weiter</FilledButton>
                            </FormKit>
                        </template>
                    </RoundedCard>
                </template>
            </OverlappingElement>

            <OverlappingElement
                class="w-3/4 lg:w-2/3 xl:w-1/2 mx-auto col-start-1 col-span-1 row-start-1 row-end-1"
                v-else-if="step === 3"
            >
                <template #under>3</template>
                <template #default>
                    <RoundedCard :back-emit="true" @back="step = 2" color="modal" :spinner="submitLoading">
                        <template #title>Registrierung</template>
                        <template #default>
                            <FormKit
                                type="form"
                                @submit="submit"
                                :actions="false"
                                ref="form3"
                                :classes="{ form: 'grid' }"
                            >
                                <div class="italic font-bold text-lg my-4">Login Daten</div>

                                <FormKit
                                    type="email"
                                    placeholder="E-Mailadresse*"
                                    v-model="model.email"
                                    :errors="errors.email"
                                    validation="required:trim"
                                    :validation-messages="{ required: 'E-Mailadresse ist erforderlich.' }"
                                />
                                <FormKit
                                    type="password"
                                    placeholder="Passwort*"
                                    v-model="model.plainPassword"
                                    :errors="errors.plainPassword"
                                    validation="required:trim"
                                    autocomplete="new-password"
                                    :validation-messages="{ required: 'Passwort ist erforderlich.' }"
                                />

                                <CustomCheckbox class="my-5" v-model="consentTos">
                                    <template #help>
                                        Hiermit akzeptiere ich die
                                        <RouterLink :to="{ name: 'terms-of-service' }" class="text-primary">
                                            Allgemeinen Geschäftsbedingungen
                                        </RouterLink>
                                        von Scoopas.
                                    </template>
                                </CustomCheckbox>

                                <CustomCheckbox class="my-5" v-model="consentDataPrivacy">
                                    <template #help>
                                        Hiermit bestätige ich die
                                        <RouterLink :to="{ name: 'data-privacy' }" class="text-primary"
                                            >Datenschutzerklärung</RouterLink
                                        >
                                        von Scoopas.
                                    </template>
                                </CustomCheckbox>

                                <FilledButton
                                    class="w-full mt-3"
                                    type="submit"
                                    :disabled="!consentTos || !consentDataPrivacy"
                                >
                                    Registrierung abschließen
                                </FilledButton>
                            </FormKit>
                        </template>
                        <template #spinner>
                            <GridLoader :loading="true" color="#E4027E" />

                            <div class="text-center text-modal mt-5">Wallet wird erstellt...</div>
                        </template>
                    </RoundedCard>
                </template>
            </OverlappingElement>
            <OverlappingElement
                class="w-3/4 lg:w-2/3 xl:w-1/2 mx-auto col-start-1 col-span-1 row-start-1 row-end-1"
                v-else-if="step === 4"
            >
                <template #under>4</template>
                <template #default>
                    <RoundedCard color="modal">
                        <VfFeedbackSuccess />
                        <div class="text-center">
                            <div class="mb-10">
                                Vielen Dank! Die Registrierung war erfolgreich. Wir haben Ihnen eine E-Mail mit weiteren
                                Informationen gesendet.
                            </div>

                            <RouterLink :to="{ name: 'login' }">
                                <FilledButton>Zum Login</FilledButton>
                            </RouterLink>
                        </div>
                    </RoundedCard>
                </template>
            </OverlappingElement>
        </Transition>
    </FilledBackgroundContainer>
</template>
